import React from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { MEDIA_QUERY, COLOR } from '../styles/variables';
import { resetFontFeatureStyle } from '../styles/common';
import { COMMON_TEXT } from '../utils/word';

const About = (): JSX.Element => {
  return (
    <DIV_Wrap>
      <P_Intro>{COMMON_TEXT.ABOUT}です。</P_Intro>
      <H2_Title>
        <FontAwesomeIcon icon={faCheckSquare} />
        <SPAN_TitleText>記事について</SPAN_TitleText>
      </H2_Title>
      <P_Text>次のようなテーマの記事を公開していく予定です</P_Text>
      <UL_List>
        <LI_ListItem>HTML/CSSの実装方法やTIPS</LI_ListItem>
        <LI_ListItem>
          React（ReactNative）やTypeScriptの困りごとの解決方法
        </LI_ListItem>
        <LI_ListItem>
          チームで働く上のナレッジ、デザイナーとエンジニア間でのやりとりの注意点
        </LI_ListItem>
      </UL_List>
    </DIV_Wrap>
  );
};

const DIV_Wrap = styled.div`
  position: relative;
  font-size: 1.4rem;
`;

const P_Intro = styled.p`
  margin: 1em 0;
`;

const H2_Title = styled.h2`
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 1.6rem;
  color: ${COLOR.key};

  @media (min-width: ${MEDIA_QUERY.middle}) {
    font-size: 2rem;
  }
`;

const SPAN_TitleText = styled.span`
  margin-left: 8px;
`;

const P_Text = styled.p`
  margin-top: 20px;
  line-height: 1.8;
`;

const UL_List = styled.ul`
  margin-top: 12px;
`;

const LI_ListItem = styled.li`
  ${resetFontFeatureStyle};
  margin: 8px 0 0 1em;
  text-indent: -1em;

  &::before {
    content: '・';
  }
`;

export { About };
