import React from 'react';
import styled from '@emotion/styled';
import Layout from '../components/Layout';
import { Seo } from '../components/Seo';
import { CommonBlock } from '../components/CommonBlock';
import { CommonContent } from '../components/CommonContent';
import { About } from '../components/About';
import { META_INFO, MODULE_TITLE } from '../utils/word';

export default ({ location }): JSX.Element => (
  <Layout>
    <Seo pagetitle={META_INFO.ABOUT.TITLE} pagepath={location.pathname} />

    <CommonContent>
      <DIV_BlockWrapper>
        <CommonBlock title={MODULE_TITLE.ABOUT_BLOG}>
          <About />
        </CommonBlock>
      </DIV_BlockWrapper>

      <DIV_BlockWrapper>
        <CommonBlock title="プライバシーポリシー、免責事項">
          <DL_Content>
            <DT_Title>広告の配信について</DT_Title>
            <DD_Explain>
              当サイトは第三者配信の広告サービス「Google Adsense
              グーグルアドセンス」を利用しています。
            </DD_Explain>
            <DD_Explain>
              広告配信事業者は、ユーザーの興味に応じた広告を表示するためにCookie（クッキー）を使用することがあります。
            </DD_Explain>
            <DD_Explain>
              Cookie（クッキー）を無効にする設定およびGoogleアドセンスに関する詳細は「
              <A_TextLink href="https://policies.google.com/technologies/ads?gl=jp">
                広告 – ポリシーと規約 – Google
              </A_TextLink>
              」をご覧ください。
            </DD_Explain>
            <DD_Explain>
              第三者がコンテンツおよび宣伝を提供し、訪問者から直接情報を収集し、訪問者のブラウザにCookie（クッキー）を設定したりこれを認識したりする場合があります。
            </DD_Explain>
          </DL_Content>

          <DL_Content>
            <DT_Title>アクセス解析ツールについて</DT_Title>
            <DD_Explain>
              当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。
            </DD_Explain>
            <DD_Explain>
              このGoogleアナリティクスはトラフィックデータの収集のためにCookieを使用しています。このトラフィックデータは匿名で収集されており、個人を特定するものではありません。この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。この規約に関して、
              <A_TextLink href="https://marketingplatform.google.com/about/analytics/terms/jp/">
                Google アナリティクス利用規約
              </A_TextLink>
              をご確認ください。
            </DD_Explain>
          </DL_Content>

          <DL_Content>
            <DT_Title>免責事項</DT_Title>
            <DD_Explain>
              当サイトで掲載している画像の著作権・肖像権等は各権利所有者に帰属致します。権利を侵害する目的ではございません。記事の内容や掲載画像等に問題がございましたら、各権利所有者様本人が直接メールでご連絡下さい。確認後、対応させて頂きます。
            </DD_Explain>
            <DD_Explain>
              当サイトからリンクやバナーなどによって他のサイトに移動された場合、移動先サイトで提供される情報、サービス等について一切の責任を負いません。
            </DD_Explain>
            <DD_Explain>
              当サイトのコンテンツ・情報につきまして、可能な限り正確な情報を掲載するよう努めておりますが、誤情報が含まれたり、情報が古くなってたりする可能性もございます。
            </DD_Explain>
            <DD_Explain>
              当サイトに掲載された内容によって生じた損害等の一切の責任を負いかねますのでご了承ください。
            </DD_Explain>
          </DL_Content>
        </CommonBlock>
      </DIV_BlockWrapper>
    </CommonContent>
  </Layout>
);

const DIV_BlockWrapper = styled.div`
  & + & {
    margin-top: 40px;
  }
`;

const DL_Content = styled.dl`
  line-height: 1.8;

  & + & {
    margin-top: 40px;
  }
`;

const DT_Title = styled.dt`
  font-size: 2rem;
  font-weight: bold;
`;

const DD_Explain = styled.dd`
  margin-top: 8px;
`;

const A_TextLink = styled.a`
  text-decoration: underline;
`;
